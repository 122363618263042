import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies } from '../../../../utils/styles';

const { width, height } = configs;

class PopupWelcomeWar extends Popup {
  latestWar = {};
  numberOfMachinesToEarn = 0;
  loading = false;

  constructor(scene, value, generatedNetworth) {
    super(scene, 'popup-welcome-war', {
      // openOnCreate: true,
      destroyWhenClosed: true,
      ribbon: 'ribbon-welcome',
      hasGlow: true,
      onClose: () => scene.events.emit('s-update-last-time-seen-war-result'),
    });

    this.line1Y = height / 2 + 360;
    this.line2Y = this.line1Y + 60;
    this.line3Y = this.line2Y + 40;
    const reputationY = this.popup.y - 140;
    this.numberGap = 370;

    const leftMargin = this.popup.x - this.popup.width / 2;
    const topMargin = this.popup.y - this.popup.height / 2;

    this.valueText = scene.add
      .text(
        leftMargin + this.popup.width * 0.28,
        topMargin + this.popup.height * 0.2,
        `+${formatter.format(value)}\nxGOLD`,
        {
          fontSize: '88px',
          color: '#fff',
          fontFamily: fontFamilies.extraBold,
          align: 'center',
        }
      )
      .setOrigin(0.5, 0);
    this.valueText.setStroke('#389d2a', 20);
    this.add(this.valueText);

    this.reputation = scene.add
      .text(width / 2 - 30, reputationY, `+${generatedNetworth.toLocaleString()}`, {
        fontSize: '72px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.reputationStar = scene.add
      .image(this.reputation.x + this.reputation.width + 30, reputationY, 'icon-star-medium')
      .setOrigin(0, 0.5);
    this.add(this.reputation);
    this.add(this.reputationStar);

    this.earnValueText = scene.add
      .text(width / 2 - this.numberGap, this.line1Y, '0', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.earnValueText);
    this.earnTokenText = scene.add
      .text(width / 2 - this.numberGap, this.line2Y, 'xGOLD', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.earnTokenText);

    this.defendValueText = scene.add
      .text(width / 2, this.line1Y, '0', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.defendValueText);
    this.defendTokenText = scene.add
      .text(width / 2, this.line2Y, 'xGOLD', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.defendTokenText);
    this.defendNoLossText = scene.add
      .text(width / 2, this.line1Y, 'No Loss', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.defendNoLossText);

    this.attackValueText = scene.add
      .text(width / 2 + this.numberGap, this.line1Y, '0', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.attackValueText);
    this.attackTokenText = scene.add
      .text(width / 2 + this.numberGap, this.line2Y, 'xGOLD', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.attackTokenText);
    this.attackNoAttackText = scene.add
      .text(width / 2 + this.numberGap, this.line1Y, 'No Attack', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.attackNoAttackText);
    this.attackLostValueText = scene.add
      .text(width / 2 + this.numberGap - 40, this.line3Y + 50, '0', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.attackLostValueText);
    this.attackLostIcon = scene.add
      .image(
        width / 2 + this.numberGap + this.attackLostValueText.width / 2 + 20,
        this.line3Y + 50,
        'icon-gangster-small'
      )
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.attackLostIcon);

    scene.events.on('s-set-war-history-latest', (data) => {
      // do nothing if popup is already closed (& destroyed)
      if (!data || !this.active) return;

      this.reset();
      const {
        tokenEarnFromEarning,
        numberOfMachinesToEarn,
        tokenEarnFromAttacking,
        machinesLost,
        tokenStolen,
        attackUsers,
      } = data;

      // earn
      this.earnValueText.text = `+${formatter.format(tokenEarnFromEarning || 0)}`;
      this.earnValueText.setVisible(true);
      this.earnTokenText.setVisible(true);
      this.numberOfMachinesToEarn = numberOfMachinesToEarn;

      // defend
      if (!tokenStolen) {
        this.defendNoLossText.setVisible(true);
      } else {
        this.defendTokenText.setVisible(true);
        this.defendValueText.text = `-${formatter.format(tokenStolen)}`;
        this.defendValueText.setVisible(true);
      }

      // attack
      if (!attackUsers?.length) {
        this.attackNoAttackText.setVisible(true);
      } else {
        this.attackTokenText.setVisible(true);
        this.attackValueText.text = `+${formatter.format(tokenEarnFromAttacking)}`;
        this.attackValueText.setVisible(true);

        if (!!machinesLost) {
          this.attackLostValueText.text = `-${formatter.format(machinesLost)}`;
          this.attackLostValueText.setVisible(true);
          this.attackLostIcon.x = width / 2 + this.numberGap + this.attackLostValueText.width / 2 + 20;
          this.attackLostIcon.setVisible(true);
        }
      }
    });

    scene.events.on('s-set-claimable-status', ({ claimable, active }) => {
      // do nothing if popup is already closed (& destroyed)
      if (!this.active) return;

      if (this.buttonClaim) {
        this.remove(this.buttonClaim);
        this.buttonClaim.destroy(true);
      }
      this.buttonClaim = new TextButton(
        scene,
        width / 2,
        height / 2 + this.popup.height / 2 - 20,
        'button-blue',
        'button-blue-pressed',
        () => this.close(),
        'Back',
        { sound: 'close', fontSize: '82px' }
      );
      this.add(this.buttonClaim);
    });

    scene.events.emit('s-get-war-history-latest');
    scene.events.emit('s-get-claimable-status');
  }

  reset() {
    this.earnValueText.setVisible(false);
    this.earnTokenText.setVisible(false);
    this.defendValueText.setVisible(false);
    this.defendTokenText.setVisible(false);
    this.defendNoLossText.setVisible(false);
    this.attackValueText.setVisible(false);
    this.attackTokenText.setVisible(false);
    this.attackNoAttackText.setVisible(false);
    this.attackLostValueText.setVisible(false);
    this.attackLostIcon.setVisible(false);
  }
}

export default PopupWelcomeWar;
