import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies } from '../../../../utils/styles';
import { formatUsername } from '../../../../utils/strings';

const { width, height } = configs;

class PopupWelcomeEndGame extends Popup {
  constructor(scene, value) {
    super(scene, 'popup-small', { title: 'Thanks for playing', destroyWhenClosed: true, noCloseBtn: true });

    this.scene = scene;
    this.buttonClaim = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'btn-follow',
      'btn-follow-pressed',
      () => {
        const url = `https://twitter.com/gangsterarena`;
        window.open(url, '_blank');
      },
      '',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.buttonClaim);

    this.overText = scene.add
      .text(this.popup.x, this.popup.y - this.popup.height / 2 + 180, 'This season is over!', {
        fontSize: '64px',
        fontFamily: fontFamilies.bold,
        color: colors.black,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.overText);

    this.usernameText = scene.add
      .text(this.popup.x, this.overText.y + 100, '@', {
        fontSize: '64px',
        fontFamily: fontFamilies.extraBold,
        color: colors.brown,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.usernameText);

    this.rewardText = scene.add
      .text(this.popup.x, this.usernameText.y + 120, 'Your rewards distributed:', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: colors.black,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.rewardText);

    const iconX = this.popup.x - 160;
    const textX = iconX + 100;
    this.ethIcon = scene.add.image(iconX, this.rewardText.y + 100, 'icon-eth-small').setOrigin(0, 0.5);
    this.add(this.ethIcon);
    this.ethRewardText = scene.add
      .text(textX, this.rewardText.y + 100, '-/-', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: colors.black,
        align: 'center',
      })
      .setOrigin(0, 0.5);
    this.add(this.ethRewardText);

    this.tokenIcon = scene.add.image(iconX, this.ethRewardText.y + 100, 'icon-coin-small').setOrigin(0, 0.5);
    this.add(this.tokenIcon);
    this.tokenRewardText = scene.add
      .text(textX, this.ethRewardText.y + 100, '-/-', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: colors.black,
        align: 'center',
      })
      .setOrigin(0, 0.5);
    this.add(this.tokenRewardText);

    this.blastGoldIcon = scene.add.image(iconX, this.tokenRewardText.y + 100, 'icon-blast-gold').setOrigin(0, 0.5);
    this.blastGoldIcon.scale = 0.85;
    this.add(this.blastGoldIcon);
    this.blastGoldRewardText = scene.add
      .text(textX, this.tokenRewardText.y + 100, '-/-', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: colors.black,
        align: 'center',
      })
      .setOrigin(0, 0.5);
    this.add(this.blastGoldRewardText);

    scene.events.on('s-set-end-game-reward', (data) => {
      // do nothing if popup is already closed (& destroyed)
      if (!this.active) return;

      const {
        username,
        ethReward,
        ethDistributed,
        tokenReward,
        tokenDistributed,
        blastGoldReward,
        blastGoldDistributed,
      } = data;
      this.usernameText.text = formatUsername({ username, MAX_USERNAME_LENGTH: 24 });
      this.ethRewardText.text = `${formatter.format(ethDistributed)}/${formatter.format(ethReward)}`;
      this.tokenRewardText.text = `${formatter.format(tokenDistributed)}/${formatter.format(tokenReward)}`;
      this.blastGoldRewardText.text = `${formatter.format(blastGoldDistributed)}/${formatter.format(blastGoldReward)}`;
    });
  }

  onOpen() {
    this.scene.events?.emit('s-get-end-game-reward');
  }
}

export default PopupWelcomeEndGame;
