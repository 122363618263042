import axios from 'axios';
import { useEffect } from 'react';

import { saveIpV6 } from '../services/user.service';

const useSaveUserIpV6 = ({ userId }) => {
  useEffect(() => {
    if (userId) saveUserIp();
  }, [userId]);

  const saveUserIp = async () => {
    try {
      const ipRes = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
      const ipV6 = ipRes.data.match(/ip=([^\s]+)/)?.[1];
      console.log('ipV6', ipV6);
      await saveIpV6({ ipV6 });
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  };
};

export default useSaveUserIpV6;
