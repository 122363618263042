import Phaser from 'phaser';

import AuctionHeader from '../components/action-buttons/AuctionHeader';
import Header from '../components/action-buttons/Header';
import AuctionCountdown from '../components/common/AuctionCountdown';
import AuctionItems from '../components/common/AuctionItems';
import PopupAuctionBidding from '../components/popup/PopupAuctionBidding';
import PopupConfirmBidding from '../components/popup/PopupConfirmBidding';
import PopupBiddingDone from '../components/popup/PopupBiddingDone';
import PopupBiddingHistory from '../components/popup/PopupBiddingHistory';
import PopupAuctionItemHistory from '../components/popup/PopupAuctionItemHistory';
import PopupAuctionItemHistoryDetail from '../components/popup/PopupAuctionItemHistoryDetail';
import AuctionMode from '../components/common/AuctionMode';
import configs from '../configs/configs';

const { width, height } = configs;

class AuctionScene extends Phaser.Scene {
  loaded = false;
  auctionItems = null;
  globalEvents = [];

  constructor() {
    super('AuctionScene');
  }

  // listeners for global events here
  addGlobalEventListener(name, fn) {
    this.globalEvents.push(name);
    this.game.events.on(name, fn, this);
  }

  createLocalToGlobalEventFlow(name) {
    this.events.on(`s-${name}`, (data) => this.game.events.emit(`g-${name}`, data), this);
  }

  createGlobalToLocalEventFlow(name) {
    this.addGlobalEventListener(`g-${name}`, (data) => this.events.emit(`s-${name}`, data));
  }

  initListeners() {
    // create event flow
    const localToGlobalEvents = [
      'get-auction-items',
      'create-bidding',
      'get-bidding-history',
      'get-auction-item-bidding-history',
      'get-auction-item-bidding-history-detail',
    ];
    const globalToLocalEvents = [
      'set-auction-items',
      'create-bidding-success',
      'create-bidding-fail',
      'set-bidding-history',
      'set-auction-item-bidding-history',
      'set-auction-item-bidding-history-detail',
      'set-auction-end-time',
      'set-next-auction-prize',
      'set-poor-token-balance',
      'set-networth-house',
      'set-balances',
      'set-x-token-balance',
    ];

    for (const event of localToGlobalEvents) {
      this.createLocalToGlobalEventFlow(event);
    }

    for (const event of globalToLocalEvents) {
      this.createGlobalToLocalEventFlow(event);
    }

    // interact with game components

    // listen to global event
    this.addGlobalEventListener('g-set-auction-items', (data) => (this.auctionItems = data));
    this.addGlobalEventListener('g-auction-changed', () => this.refreshItems(true));
  }

  emitStartingEvents() {
    const startingEvents = [
      'g-get-auction-items',
      'g-get-auction-end-time',
      'g-get-next-auction-prize',
      'g-get-balances',
      'g-get-x-token-balance',
      'g-get-poor-token-balance',
    ];

    for (const event of startingEvents) {
      this.game.events.emit(event);
    }
  }

  create() {
    this.initListeners();
    this.emitStartingEvents();
  }

  // remove global event listeners when scene destroy
  removeListeners() {
    for (const event of this.globalEvents) {
      this.game.events.removeListener(event);
    }
  }

  shutdown() {
    this.removeListeners();
  }

  preload() {
    if (this.loaded) return;
    this.background = this.add.image(width / 2, height / 2, 'auction-bg').setOrigin(0.5, 0.5);
    this.background.setDisplaySize(width, height);
    this.add.existing(this.background);

    this.auctionHeader = new AuctionHeader(this);
    this.add.existing(this.auctionHeader);

    this.header = new Header(this, 330, {
      tokenClick: () => {
        this.scene.pause('AuctionScene');
        this.scene.resume('MainScene');
        this.scene.bringToTop('MainScene');
        const mainScene = this.scene.get('MainScene');
        mainScene.popupSwap?.open();
      },
      ethClick: () => {
        this.scene.pause('AuctionScene');
        this.scene.resume('MainScene');
        this.scene.bringToTop('MainScene');
        const mainScene = this.scene.get('MainScene');
        mainScene.popupDeposit?.open();
      },
    });
    this.add.existing(this.header);

    this.auctionCountdown = new AuctionCountdown(this);
    this.add.existing(this.auctionCountdown);

    this.auctionMode = new AuctionMode(this);
    this.add.existing(this.auctionMode);

    this.popupAuctionBidding = new PopupAuctionBidding(this);
    this.add.existing(this.popupAuctionBidding);

    this.popupConfirmBidding = new PopupConfirmBidding(this);
    this.add.existing(this.popupConfirmBidding);

    this.popupBiddingDone = new PopupBiddingDone(this);
    this.add.existing(this.popupBiddingDone);

    const pluginLoader = this.load.scenePlugin({
      key: 'rexuiplugin',
      url: '/libs/rexui.min.js',
      sceneKey: 'rexUI',
    });
    pluginLoader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.popupBiddingHistory = new PopupBiddingHistory(this);
      this.add.existing(this.popupBiddingHistory);

      this.popupAuctionItemHistory = new PopupAuctionItemHistory(this);
      this.add.existing(this.popupAuctionItemHistory);

      this.popupAuctionItemHistoryDetail = new PopupAuctionItemHistoryDetail(this);
      this.add.existing(this.popupAuctionItemHistoryDetail);

      this.items = new AuctionItems(this);
      this.add.existing(this.items);
    });
  }

  update() {}

  refreshItems = (reload = false) => {
    if (!reload) {
      const removedItems = this.items;
      const mode = this.items.mode;
      const page = this.items.page;
      this.items = new AuctionItems(this, reload ? null : this.auctionItems);
      this.items.showMode(mode, page);
      this.add.existing(this.items);
      removedItems.clearItems();
      removedItems.destroy();
      this.auctionMode?.switchMode(mode, true);
    } else {
      const removedItems = this.items;
      this.items = new AuctionItems(this);
      this.add.existing(this.items);
      removedItems.clearItems();
      removedItems.destroy();
      this.auctionMode?.switchMode('xGREED', true);
      this.events.emit('g-get-auction-end-time');
      this.events.emit('g-get-next-auction-prize');
    }
  };
}

export default AuctionScene;
